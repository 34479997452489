import moment from 'moment';
import {SignupData} from 'store/reducers/authentication';
import api from 'store/services/api';

export interface LoginRequest {
  username: string;
  password: string;
  platform?: string;
  nextRoute?: string;
}

export interface RecoverPassword {
  uidb64: string;
  token: string;
  new_password1: string;
  new_password2: string;
}

export const loginService = (data: LoginRequest) =>
  api('/api/v1/auth/jwt/', {method: 'post', data});

export const restorePasswordService = (data: {email: string}) =>
  api('/forgot_password/', {method: 'post', data});

export const signupService = (data: SignupData) => {
  const {email, name, access_code, password, birthdate} = data;

  const [first_name, ...tail] = name.split(' ');
  const last_name = tail.join(' ');
  const last_username_name = tail[tail.length - 1];

  const username = first_name
    .concat(last_username_name)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replace(/\s\s+/g, '')
    .concat(moment(birthdate, 'YYYY-MM-DD').format('DDMMYYYY'));

  return api('/letrus_signup/', {
    method: 'post',
    data: {
      first_name,
      last_name,
      email,
      username,
      access_code,
      birth_date: birthdate,
      password1: password,
      password2: password,
    },
  });
};

export interface AccessRequest {
  token: string;
}

export const accessService = (data: AccessRequest) =>
  api('/api/v1/schoolsnetwork/auth/', {method: 'post', data});

export const RecoverPasswordService = (data: RecoverPassword) =>
  api('/password_reset_confirm/', {
    method: 'post',
    data,
  });

export const validateUsername = (data: {username: string}) =>
  api('/validate_user_username/', {method: 'post', data});
