import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import api, {GetParams} from './api';

export const fetchNotificationsService = (params?: GetParams) =>
  api('/api/v1/notification_logs/?channel=web', {
    params: {
      ...params,
      created__year: new Date().getFullYear(),
    },
  });
export const updateNotificationByIdService = (
  notificationId: number,
  notificationData: Partial<LetrusApi.NotificationLog>,
) =>
  api(`/api/v1/notification_logs/${notificationId}/`, {
    method: 'patch',
    data: notificationData,
  });
