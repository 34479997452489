import axios, {AxiosInstance} from 'axios';
import Qs from 'qs';
import {Store} from 'redux';
import {logoutUserRequest} from 'store/reducers/authentication';
import {getCookie} from 'utils/functions/cookies';

export interface GetParams {
  limit?: number;
  offset?: number;
  ordering?: string;
  search?: string;
}

export type Methods =
  | 'get'
  | 'put'
  | 'post'
  | 'head'
  | 'delete'
  | 'patch'
  | 'options';

export interface FetchOptions {
  method?: Methods;
  data?: any;
  params?: Record<string, any>;
  headers?: Record<string, string>;
  withCredentials?: boolean;
}

export interface ResponseError {
  response: {
    data: Record<string, any>;
  };
}

const API_URL = process.env.REACT_APP_API_URL;

const axiosHTTPClient: AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 360 * 1000,
  paramsSerializer: (params: any) =>
    Qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
});

const UNAUTHORIZED = 401;

export const setupInterceptors = (store: Store): void => {
  const {dispatch} = store;

  axiosHTTPClient.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error?.response?.status ?? 0;

      if (status === UNAUTHORIZED) {
        dispatch(logoutUserRequest());
        window.location.href = '/login';
      }
      return Promise.reject(error);
    },
  );
};

const defaultOptions: FetchOptions = {method: 'get'};

const api = (
  url: string,
  {method, data, params, headers, withCredentials = true} = defaultOptions,
) =>
  axiosHTTPClient({
    method,
    data,
    url,
    params,
    headers: headers || {
      'x-csrftoken': getCookie('csrftoken'),
      'Content-Type': 'application/json',
    },
    withCredentials,
  }).catch((err: ResponseError) => {
    throw err;
  });

export default api;
